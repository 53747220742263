import React, { useEffect } from 'react'
import { TextFieldElement, useFormContext } from 'react-hook-form-mui'
import { Stack } from '@mui/material'

import PasswordInput from '../elements/PasswordInput'
import SubmitButton from '../elements/SubmitButton'

export default (props) => {
  const { defaultValue, isStudent } = props
  const { watch, reset, setError, clearErrors } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  useEffect(() => {
    const subscription = watch(({ password, passwordConfirm }) => {
      if (!!passwordConfirm && password !== passwordConfirm) {
        setError('passwordConfirm', { message: 'パスワードが一致しません' })
      } else {
        clearErrors('passwordConfirm')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Stack spacing={4} py={2} alignItems='center'>
      <Stack spacing={2} width='100%'>
        <TextFieldElement
          name='displayName'
          label={isStudent ? 'チーム名' : 'お名前'}
          autoComplete={isStudent ? null : 'username'}
          required
          fullWidth
          autoFocus
        />

        {isStudent ? (
          <TextFieldElement name='teamId' label='チームID' required fullWidth />
        ) : (
          <TextFieldElement
            name='email'
            label='メールアドレス'
            type='email'
            autoComplete='email'
            required
            fullWidth
          />
        )}

        <PasswordInput />
        <PasswordInput isConfirm />
      </Stack>

      <SubmitButton
        label={`${isStudent ? 'チーム' : '教員'}用アカウントを作成`}
        sx={{ width: 'fit-content' }}
      />
    </Stack>
  )
}
