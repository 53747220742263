import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { FormContainer } from 'react-hook-form-mui'

import AlertDialog from '../containers/AlertDialog'
import Snackbar from '../containers/Snackbar'
import Firebase from '../containers/Firebase'
import FirestoreInvitation from '../containers/FirestoreInvitation'

import SignUpFormContent from '../components/SignUpFormContent'
import Card from '../elements/Card'

import logo from '../assets/logo.png'

export default () => {
  const alertDialog = AlertDialog.useContainer()
  const snackbar = Snackbar.useContainer()
  const firebase = Firebase.useContainer()
  const firestoreInvitation = FirestoreInvitation.useContainer()
  const navigate = useNavigate()
  const { docData: invitation = {} } = firestoreInvitation
  const isStudent = !!invitation.labName

  if (!invitation.id) {
    return null
  }

  function handleSubmit({ passwordConfirm, ...info }) {
    const operation = 'アカウントを作成'
    const loadingDialog = alertDialog.open({ operation, isLoading: true })
    firestoreInvitation
      .tryRequest({ info })
      .then(() => {
        snackbar.open({ operation })
        firebase
          .signIn(info)
          .then(() => navigate('/'))
          .catch(console.log)
          .finally(() => loadingDialog.close())
      })
      .catch(() => {
        snackbar.open({ operation, isError: true })
        loadingDialog.close()
      })
  }

  return (
    <Container maxWidth='sm' sx={{ py: 4 }}>
      <Card p={4} sx={{ width: '100%' }}>
        <Stack spacing={4}>
          <Stack spacing={2} alignItems='center'>
            <img
              src={logo}
              alt='Donuts-Lab'
              style={{ width: '96%', maxWidth: '360px', aspectRatio: 5.2 }}
            />
            <Typography variant='caption'>アカウントを作成してください！</Typography>
          </Stack>

          <TableContainer variant='outlined' component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>学校名</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{invitation.schoolName}</TableCell>
                </TableRow>
                {!!isStudent && (
                  <TableRow>
                    <TableCell>ラボ名</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{invitation.labName}</TableCell>
                  </TableRow>
                )}
                {/* <TableRow>
                  <TableCell>権限</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{ja[invitation.info?.role]}</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>

          <FormContainer onSuccess={handleSubmit}>
            <SignUpFormContent {...{ isStudent }} />
          </FormContainer>

          <Typography variant='caption'>
            アカウントを作成すると、
            <Link href='/terms.pdf' target='_blank'>
              利用規約
            </Link>
            に同意したものとみなされます。
          </Typography>
        </Stack>
      </Card>
    </Container>
  )
}
